let getters = {
  getUserPoints(state) {
    return state.userPoints && state.userPoints.length > 0
      ? state.userPoints[0]
      : [];
  },

  muteNotifications(state) {
    return state.muteNotifications;
  },
};

export default getters;
